@tailwind base;
@tailwind components;
@tailwind utilities;

/* ----- LAYER ----- */

@layer base {
    h1 {
        @apply text-3xl;
    }
    h2 {
        @apply text-2xl;
    }
}

/* ----- INPUT CSS ----- */
.input-primary {
    @apply text-sm w-full rounded-md py-1.5 px-2.5 border-gray-300 border-[1px] disabled:cursor-not-allowed disabled:bg-gray-100 focus:ring-clobasoft-medium-blue focus:border-clobasoft-medium-blue;
}

.search-primary {
    @apply text-sm py-1.5 px-2.5 w-full sm:w-1/2 text-gray-900 bg-white rounded-r-md border-[1px] border-gray-300 disabled:cursor-not-allowed disabled:bg-gray-100 focus:ring-clobasoft-medium-blue focus:border-clobasoft-medium-blue;
}

.select-primary {
    @apply focus:outline-clobasoft-light-blue;
}

.radio-primary {
    @apply text-clobasoft-medium-blue focus:ring-clobasoft-light-blue;
}

.checkbox-primary {
    @apply h-4 w-4 rounded border-gray-300 text-clobasoft-medium-blue focus:ring-clobasoft-light-blue;
}

/* ----- TEXT CSS ----- */
.validation-success-text {
    @apply text-xs text-green-600 font-semibold;
}

.validation-error-text {
    @apply text-xs text-red-600 font-semibold;
}

.text-default {
    @apply bg-gray-500;
}

.text-success {
    @apply bg-green-600;
}

.text-danger {
    @apply bg-red-600;
}

.text-info {
    @apply bg-blue-700;
}

.text-warning {
    @apply bg-amber-600;
}

/* ----- LABEL BACKGROUND CSS ----- */
.label-background {
    @apply py-1 px-[6px] rounded-md text-xs text-white font-semibold;
}

/* ----- BUTTON CSS ----- */
.btn-primary {
    @apply p-2 transition duration-300 rounded-md text-white bg-clobasoft-dark-blue hover:bg-clobasoft-medium-blue focus:bg-clobasoft-medium-blue focus:ring focus:outline-none disabled:bg-clobasoft-light-blue disabled:cursor-not-allowed;
}

.btn-info {
    @apply transition duration-300 text-sm rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:bg-blue-600 focus:ring focus:outline-none disabled:bg-blue-400 disabled:cursor-not-allowed;
}

.btn-success {
    @apply transition duration-300 text-sm rounded-md text-white bg-green-600 hover:bg-green-700 focus:bg-green-600 focus:ring focus:outline-none disabled:bg-green-400 disabled:cursor-not-allowed;
}

.btn-danger {
    @apply transition duration-300 text-sm rounded-md text-white bg-rose-500 hover:bg-rose-600 focus:bg-rose-500 focus:ring focus:outline-none disabled:bg-rose-400 disabled:cursor-not-allowed;
}

.btn-none {
    @apply transition duration-300 text-sm rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:bg-gray-500 focus:ring focus:outline-none disabled:bg-gray-400 disabled:cursor-not-allowed;
}

.btn-warning {
    @apply transition duration-300 text-sm rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:bg-amber-500 focus:ring focus:outline-none disabled:bg-amber-400 disabled:cursor-not-allowed;
}

.btn-light {
    @apply underline transition duration-300 text-sm rounded-md text-gray-500 hover:text-rose-500  focus:outline-none disabled:text-gray-400 disabled:cursor-not-allowed;
}

/* ----- REACT-DATA-TABLE-COMPONENT ----- */
.rdt_Pagination > div > svg > path {
    display: none;
}

.rdt_TableHeadRow {
    background-color: #EEEEEE !important;
    font-weight: bolder;
}

.gMliOo > svg > path {
    display: none;
}

/* ---- TAILWIND ELEMENTS ---- */
.nav-tabs .nav-link.active {
    font-weight: bold;
}